import React from 'react';
import PropTypes from 'prop-types';

import { FormHelpText, FormLabel } from '../../index';
import { Wrapper, ChildWrapper } from './styled';

const FormGroup = ({
  name,
  id,
  disabled,
  label,
  labelProps,
  error,
  labelTextPlacement,
  helpText,
  helpTextProps,
  children,
  required,
  block,
  ...restProps
}) => {
  const text = error || helpText;
  const status = error && 'error';
  return (
    <Wrapper
      required={required}
      labelTextPlacement={labelTextPlacement}
      block={block}
      {...restProps}
    >
      <FormLabel
        htmlFor={id || name}
        className="form-label"
        display="block"
        {...labelProps}
        style={{ width: label === 'Alternate Contact Number' && '100%' }}
      >
        {label}
      </FormLabel>
      <ChildWrapper block={block}>
        {children({
          id: id || name,
          name,
          disabled,
          error,
          status,
          required,
        })}
        {text && (
          <FormHelpText
            className="form-help-text"
            color={status || ''}
            {...helpTextProps}
          >
            {text}
          </FormHelpText>
        )}
      </ChildWrapper>
    </Wrapper>
  );
};

FormGroup.defaultProps = {
  helpText: '',
  disabled: false,
  required: false,
  block: true,
  label: '',
  labelProps: {},
  helpTextProps: {},
};

FormGroup.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  helpText: PropTypes.string,
  label: PropTypes.string,
  labelTextPlacement: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  labelProps: PropTypes.object,
  helpTextProps: PropTypes.object,
  block: PropTypes.bool,
};

export default FormGroup;
